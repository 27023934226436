import { FaCircleUser, FaSquareWhatsapp, FaSquarePhone, FaClockRotateLeft } from "react-icons/fa6";
import './Profile.css';
import { CONSTANTS } from "../../constants/app.constants";
import { useState, useEffect } from "react";
import { get } from "../../services/http";

export default function Profile ({data={}}) {
    console.log(data);
    let weekNumber = '-';
    try {
        const currentDate = new Date();
        const startDate = new Date(data.joiningDate);
        console.log(currentDate - startDate)
        let days = Math.floor((currentDate - startDate) /
            (24 * 60 * 60 * 1000));
        
        weekNumber = Math.ceil(days / 7);
    } catch (e) {
        console.error(e);
    }

    let interactionText = "Not Found";
    let lastStatus = "-";
    let interactionHistoryCompleted = 0;
    try {
        interactionText = data.interactionHistory.map(history => {
            if (history.feedback == 'resolved') interactionHistoryCompleted++;
            return history.exercise + " -> " + history.feedback
        })
        interactionText = interactionText.join("\n")
        lastStatus = data.interactionHistory &&  data.interactionHistory[data.interactionHistory.length -1].feedback;

    } catch (e) {
        //ignored
    }
    return (
        <>
        {
            data.whatsappNumber &&
        <div className="profile-tile">
            <FaCircleUser size='40px' />
            <h3>{data.whatsappName}</h3>
            {
                data.suicidalRisk &&
                <div className="risk-label">
                    Suicide Risk
                </div>
            }
            <pre>Preferred Name: {data.name}</pre>
            <div className="profile-phone-numbers">
                <div className="calling-num">
                    <FaSquarePhone size='20px' className="num-icon" /> {data.callingNumber}
                </div>
                <div className="whatsapp-num">
                    <FaSquareWhatsapp size='20px' className="num-icon" /> {data.whatsappNumber}
                </div>
            </div>
            <div className="profile-data-tile">
                <div className="profile-data">
                    <h4 style={{ color: 'orange' }}>PHQ 9 Scores</h4>
                </div>
                <div className="profile-data">
                    <h4>{data.phq9InitialScore}</h4>
                    Initial
                </div>
                <div className="profile-data">
                    <h4>{data.phq9MidlineScore}</h4>
                    Midline
                </div>
                <div className="profile-data">
                    <h4>{data.phq9EndlineScore}</h4>
                    Endline
                </div>
            </div>
            <div className="profile-data-tile">
                <div className="profile-data">
                <h5>Assigned Guide</h5>
                <pre style={{ color: '#50C4FC', fontWeight: 'bold', fontSize: '18px' }}>{data.assignedGuide}</pre>
                </div>
            </div>
            <div className="profile-data-tile">
                <div className="profile-data">
                    <h4>Date of Joining Program</h4>
                    <pre>{data.joiningDate}</pre>
                </div>
                <div className="profile-data">
                    <h4>Current Week in Program</h4>
                    <pre>{weekNumber}</pre>
                </div>
            </div>
            <div className="profile-data-tile">
                <div className="profile-data">
                    <h4>Current Program Location</h4>
                    <pre>{data.programLocation}</pre>
                </div>
                <div className="profile-data">
                    <h4>Last Completed Exercise</h4>
                    <pre>{data.lastExerciseCompleted}</pre>
                </div>
            </div>
            <div className="profile-data-tile">
                <div className="profile-data">
                    <h4>Number of Guide Calls</h4>
                    <pre>{interactionHistoryCompleted}</pre>
                </div>
                <div className="profile-data">
                    <h4>Last Call Status</h4>
                    <pre>{lastStatus}</pre>
                </div>
                <div className="profile-data">
                    <h4>Interaction History</h4>
                    <FaClockRotateLeft style={{cursor: 'pointer'}} title={interactionText}/>
                </div>
            </div>
            <div className="profile-data-tile">
                <div className="profile-data">
                    <h4>Ext Name</h4>
                    <pre>{data.extName}</pre>
                </div>
                <div className="profile-data">
                    <h4>Location</h4>
                    <pre>{data.location}</pre>
                </div>
                <div className="profile-data">
                    <h4>Age</h4>
                    <pre>{data.age}</pre>
                </div>
            </div>
            <div className="profile-data-tile">
                <div className="profile-data">
                    <h4>Job Profile</h4>
                    <pre>{data.jobProfile}</pre>
                </div>
                <div className="profile-data">
                    <h4>Symptoms</h4>
                    <pre>{data.symptoms}</pre>
                </div>
            </div>
        </div>
        }
        { !data.whatsappNumber && <pre>No Profile Selected</pre>}
        </>
    )
}