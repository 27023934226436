import { FaCircleUser } from 'react-icons/fa6';
import './Users.css';
import { CONSTANTS } from '../../constants/app.constants';
import { useEffect, useState } from 'react';
import { get } from '../../services/http';
import Profile from '../Profile/Profile';

export default function Users () {
    const [users, setUsers] = useState([]);
    const [searchInput, setSearchInput] = useState('');

    const backup =             <div className="users-wrapper">
    <div className="users-col">
        <div className="user-tile">
            <FaCircleUser size='28px' /><br/>
            <pre>User 1</pre>
            <b>8310196016</b>
        </div>
    </div>
    </div>

    const handleSearchChange = (e) => {
        setSearchInput(e.target.value);
    }

    const search = async () => {
        let calls = await get(`${CONSTANTS.API_URL}/calls/history/${searchInput}`)
            .then(response => {
                return response.calls;
            })
            .catch(err => {
                console.error(err);
                return [];
            })
        get(`${CONSTANTS.API_URL}/participants/user/${searchInput}`)
            .then(data => {
                data.user.interactionHistory = calls;
                setUsers(data.user);
            })
            .catch(err => {
                console.error(err);
            })
    }

    return (
        <>
            <h3>Search Users</h3>
            <input className='search-in-users' onChange={handleSearchChange} placeholder='Enter WhatsApp Number' /><br/>
            <button className='search-submit' onClick={search}>Search</button>
            <Profile data={users} />
        </>
    )
}