import { FaSquarePhone, FaChartPie, FaUsersViewfinder } from "react-icons/fa6";
import './NavbarBody.css';
import { useContext } from 'react';
import { UserContext } from '../../UserContext';

export default function NavbarBody({expand=false, selected="calls", setSelectedTab={}}) {

    const { user, setUser } = useContext(UserContext);

    const style = {
        color: 'white',
        fontSize: '30px',
        hoverColor: 'black',
        display: 'inline',
        verticalAlign: 'middle',
        marginLeft: '12px',
        }
    
    const userNavMenu = <>
    <div 
                className={"nav-item " + (selected==='calls' ? "nav-item-selected" : "") } 
                onClick={() => setSelectedTab('calls')}
            >
                <FaSquarePhone
                    style={style} 
                />
                {
                    expand
                    ? <span>Calls</span> 
                    : <></>
                }
            </div>
            <div 
                className={"nav-item " + (selected==='users' ? "nav-item-selected" : "") }
                onClick={() => setSelectedTab('users')}
            >
                <FaUsersViewfinder
                    style={style} 
                />
                {
                    expand
                    ? <span>Users</span> 
                    : <></>
                }
            </div>
            <div 
                className={"nav-item " + (selected==='analysis' ? "nav-item-selected" : "") }
                onClick={() => setSelectedTab('analysis')}
            >
                <FaChartPie
                    style={style} 
                />
                {
                    expand
                    ? <span>Analysis</span> 
                    : <></>
                }
            </div>
    </>

    
    return (
        <div className={"navbar-body " + (expand ? "expanded" : "")}>
            {
               userNavMenu
            }
        </div>
    )
}