import axios from "axios";

const token = localStorage.getItem('token');

const options = {
    headers: {
        Authorization: token ? `Bearer ${token}` : ''
    }
}

export const post = (url, data = {}) => new Promise((resolve, reject) => {
    return axios.post(url, data, options).then(({ data }) => resolve(data)).catch((error) => {
        reject(error);
    });
});

export const get = (url, params) => new Promise((resolve, reject) => {
    return axios.get(url, {
        params,
        headers: options.headers
    }).then(({ data }) => resolve(data)).catch((error) => {
        console.log(error);
        if (error.response && (error.response.status === 401 || error.response.status === 500)) {
            window.location.href = '/login'; // Redirect to the login page
        } else {
            reject(error); // Ensure to reject the promise in case of other errors
        }
        reject(error); // Ensure to reject the promise in case of error
    });
});

export const put = (url, data = {}) => new Promise((resolve, reject) => {
    return axios.put(url, data, options).then(({ data }) => resolve(data)).catch((error) => {
        reject(error);
    });
});

export const _delete = (url) => new Promise((resolve, reject) => {
    return axios.delete(url, options).then(({ data }) => resolve(data)).catch((error) => {
        reject(error);
    });
});
