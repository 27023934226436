import logo from './logo.svg';
import { useState, useMemo } from 'react';
import './App.css';
import { UserContext } from './UserContext';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Login from './pages/Login/Login';
import Dashboard from './pages/Dashboard/Dashboard';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

function App() {

  const [user, setUser] = useState({
    token: localStorage.getItem('token'),
    loggedIn: localStorage.getItem('loggedIn'),
    role: localStorage.getItem('role')
  });

  const userValue = useMemo(() => ({user, setUser}), [user, setUser]);

  return (
    <div className="App">
    <UserContext.Provider value={userValue}>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />}></Route>
          <Route path='/dashboard' element={<Dashboard />}></Route>
          <Route path="/" element={<Navigate to="/dashboard" />} />
          {/* Catch-all route to redirect to /dashboard */}
          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Routes>
      </BrowserRouter>
      </UserContext.Provider>
    </div>
  );
}

export default App;
