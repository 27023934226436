import './UsersTopView.css';
import { FaClockRotateLeft, FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import { get } from '../../services/http';
import { CONSTANTS } from '../../constants/app.constants';
import { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

export default function UsersTopView () {
    const [users, setUsers] = useState([]);
    const [page, setPage] = useState(1); 
    const [loading, setLoading] = useState(false);
    const [guideFilter, setGuideFilter] = useState('all');
    const [suicideFilter, setSuicideFilter] = useState(false);

    useEffect(() => {
        setLoading(true);
        get(`${CONSTANTS.API_URL}/participants/compute-top-view?page=${page}&assignedGuide=${guideFilter}&suicideRisk=${suicideFilter}`)
            .then(data => {
                let finalData = Promise.all(data.participants.map(participant => {
                    let interactionHistoryCompleted = 0;
                    let interactionText = "Not Found";
                    try {
                        interactionText = participant.interactionHistory.map(history => {
                            if (history.feedback == 'resolved') interactionHistoryCompleted++;
                            return history.exercise + " -> " + history.feedback
                        });
                        let weekNumber = '-';
                        try {
                            const currentDate = new Date();
                            const startDate = new Date(participant.joiningDate);
                            console.log(currentDate - startDate)
                            let days = Math.floor((currentDate - startDate) /
                                (24 * 60 * 60 * 1000));
                            
                            weekNumber = Math.ceil(days / 7);
                            if (Number.isNaN(weekNumber)) weekNumber = '-';
                        } catch (e) {
                            console.error(e);
                        }

                        interactionText = interactionText.join("\n");
                        participant.week = weekNumber;
                        participant.interactionText = interactionText;
                        participant.interactionHistoryCompleted = interactionHistoryCompleted;
                        console.log(participant);
                        return participant;
                    } catch (e) {
                        console.error(e);
                    }
                }));
                finalData.then(participants => {
                    setUsers(participants);
                }).catch(err => {
                    console.error(err);
                }).finally(() => {
                    setLoading(false)
                })

            })
    },[page, guideFilter, suicideFilter]);

    const handleGuideChange = (e) => {
        setGuideFilter(e.target.value)
    }

    const handleSuicideChange = (e) => {
        setSuicideFilter(e.target.checked)
    }

    return (
        <>
        <Typography variant="h6" component="h6" textAlign='left' mt='12px'>
            Users Top View
        </Typography>
        <center>
        <div>
            <div style={{ width: '100%', textAlign: 'left', marginTop: '30px'}}>
                Page Number: {page}
            </div>
            {
                loading && 
                <CircularProgress />
            }
            {
                !loading &&
                <>
            <div className='top-view-filters'>
                <div className='guide-filter'
                    style={{
                        float: 'left',
                        marginTop: '20px',
                        marginBottom: '20px',
                        display: 'inline'
                    }}
                >
                <FormControl style={{ width: '200px' }}>
                    <InputLabel id="demo-simple-select-label">Select Guide</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={guideFilter}
                        label="Select Guide"
                        onChange={handleGuideChange}
                    >
                        <MenuItem value='all'>All</MenuItem>
                        <MenuItem value='Kuhu'>Kuhu</MenuItem>
                        <MenuItem value='Rashi'>Rashi</MenuItem>
                        <MenuItem value='guidea'>Guide A</MenuItem>
                        <MenuItem value='guideb'>Guide B</MenuItem>
                        <MenuItem value='guidec'>Guide C</MenuItem>
                    </Select>
                </FormControl>
                </div>

                <div className='suicide-filter'
                    style={{ 
                        float: 'right',

                    }}
                >
                    <FormControlLabel 
                        control={<Switch
                            defaultChecked={suicideFilter}
                             />} 
                        label="Suicide Risk" 
                        color="error" 
                        onChange={handleSuicideChange}
                    />
                </div>
            </div>
            <TableContainer 
                component={Paper} 
                hover
                sx={{ mt: '20px' }}
            >
                <Table sx={{ minWidth: 650 }} stickyHeader>
                <TableHead >
                <TableRow>
                    <TableCell>WhatsApp ID</TableCell>
                    <TableCell>WhatsApp Name</TableCell>
                    <TableCell>Pref. Name</TableCell>
                    <TableCell>Prog Loc</TableCell>
                    <TableCell>Prog. Week</TableCell>
                    <TableCell>Last Ex.</TableCell>
                    <TableCell>Suicidal Risk</TableCell>
                    <TableCell>Guide</TableCell>
                    <TableCell>Total Attempts</TableCell>
                    <TableCell>Resolved Calls</TableCell>
                    <TableCell>Last Call Status</TableCell>
                    <TableCell>Turn Remarks</TableCell>
                    <TableCell>Interaction History</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>

                {
                    users.length != 0 &&
                    users.map(user => {
                        return (
                            <TableRow
                            key={user.whatsappNumber}
                            hover
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >     <TableCell>{user.whatsappNumber}</TableCell>
                                <TableCell>{user.whatsappName}</TableCell>
                                <TableCell>{user.name}</TableCell>
                                <TableCell>{user.programLocation}</TableCell>
                                <TableCell>{user.week}</TableCell>
                                <TableCell>{user.lastExerciseCompleted}</TableCell>
                                <TableCell>{user.suicidalRisk ? "Yes" : "No"}</TableCell>
                                <TableCell>{user.assignedGuide}</TableCell>
                                <TableCell>{user.interactionHistory.length}</TableCell>
                                <TableCell>{user.interactionHistoryCompleted}</TableCell>
                                <TableCell>{user.interactionHistory.length !== 0 && user.interactionHistory[user.interactionHistory.length - 1].feedback}</TableCell>
                                <TableCell>{user.turnRemarks}</TableCell>
                                <TableCell><FaClockRotateLeft style={{cursor: 'pointer'}} title={user.interactionText}/></TableCell>
                            </TableRow>
                        )
                    })
                }
                </TableBody>
                </Table>
            </TableContainer>
            <div style={{ marginTop: '20px', paddingBottom: '30px' }}>
                <FaAngleLeft style={{ marginRight: '20px', cursor: 'pointer' }} onClick={() => {
                    setPage((page != 1) ? page - 1: 1);
                }}/>
                {page}
                <FaAngleRight style={{ marginLeft: '20px', cursor: 'pointer' }} onClick={() => {
                    setPage(page + 1);
                }}/>
            </div>
            </>
            }

        </div>
        </center>
        </>
    )
}