import { FaPhone } from "react-icons/fa6";
import './ScheduledCalls.css';
import Profile from "../Profile/Profile";
import { useEffect, useState } from "react";
import { get, post } from "../../services/http";
import { CONSTANTS } from "../../constants/app.constants";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import WavingHandIcon from '@mui/icons-material/WavingHand';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import StarIcon from '@mui/icons-material/Star';
import Rating from '@mui/material/Rating';
import Checkbox from '@mui/material/Checkbox';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import HandshakeIcon from '@mui/icons-material/Handshake';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    maxHeight: '90%',
    boxShadow: 24,
    p: 4,
};

export default function ScheduledCalls () {
    const [selectedProfile, setSelectedProfile] = useState({
        name: '',
        callType: '',
        whatsappNumber: '',
        callTime: '',
        eventId: '',
        dateTime: '',
        guideUsername: window.localStorage.getItem('username'),
        roster: {
            callTime: '',
            callType: '',
            feedback: '',

        }
    });

    const welcomeFormLink = `https://docs.google.com/forms/d/e/1FAIpQLSdhiqsithQIMEUwUYEk481qDb9WWTvvwVzKVAdpWV-I8fZiYA/viewform?usp=pp_url&entry.1572943156=${selectedProfile.whatsappNumber}&entry.978394497=${selectedProfile.callingNumber}&entry.699966659=${selectedProfile.name}`;
    const guideCallLink = `https://docs.google.com/forms/d/e/1FAIpQLSe_G4qn9dwCpqwAArueEPA719bVb3ISHj9rIVusbutSImD5tQ/viewform?usp=pp_url&entry.633703400=${selectedProfile.name}&entry.751531332=${selectedProfile.whatsappNumber}`;

    const ratingLabels = {
        5: "Participant has engaged strongly with the exercises",
        4: "Participant has engaged somewhat with the exercises",
        3: "Participant has engaged with the exercises very little or not at all",
        2: "Unsure",
        1: "Other"
    }

    const financeTip = `
Financial issues would be defined as:
        1. Inability to meet a basic life need; for example food, housing, medical care, routine bills, or public transport
        2. Inability to carry out a significant life activity; for example college fees or paying children's school fees
        3. Difficulty meeting daily needs due to needing to support a family member.
Note: We would not count second-order needs such as buying a car.`

    function getLabelText(value) {
        return `${value} Star${value !== 1 ? 's' : ''}, ${ratingLabels[value]}`;
    }

    const [ratingValue, setRatingValue] = useState(2);
    const [ratingHover, setRatingHover] = useState(-1);

    const [modalActive, setModalActive] = useState(false);
    const handleOpen = () => setModalActive(true);
    const handleClose = () => setModalActive(false);

    const [rosterCalls, setRosterCalls] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(false);

    const [selectedType, setSelectedType] = useState('');

    useEffect(() => {
        console.log(selectedProfile);
    }, [selectedProfile])

    useEffect(() => {
        setLoading(true)
        let allRosterCalls = [];
        get(`${CONSTANTS.API_URL}/calendar/get-rosters/${window.localStorage.getItem('username')}`)
            .then((response) => {
                let rosterCallsData = []

                response.events.map(event => {

                    try {
                        const callType = event.summary.split(']')[0].split('[')[1];
                        const callTime = event.start.dateTime.split('T')[1].split('+')[0];
                        const name = event.summary.split(']')[1].split('/')[0];
                        const dateTime = event.start.dateTime;
                        const whatsappNumber = event.summary.split('/')[1];
                        const eventId = event.id;
                        rosterCallsData.push({
                            name,
                            callType,
                            whatsappNumber,
                            callTime,
                            eventId,
                            dateTime
                        });
                    } catch(e) {
                        //ignored
                    }
                })
                allRosterCalls.push(rosterCallsData)
                setRosterCalls(rosterCallsData)
            })
            .catch(err => { 
                console.error(err);
            }).finally(() => {
                setLoading(false)
            })


    }, []);

    const submitFeedback = () => {
        post(`${CONSTANTS.API_URL}/calls/submit-feedback`,selectedProfile)
            .then(response => {
                console.log(response);
                window.location.reload();
            })
            .catch(err=> {
                console.error(err);
            })
    }

    const selectProfile = async (whatsappNumber, rosterCall) => {
        let calls = await get(`${CONSTANTS.API_URL}/calls/history/${whatsappNumber}`)
            .then(response => {
                return response.calls;
            })
            .catch(err => {
                console.error(err);
                return [];
            })
        console.log(calls)
    
        await get(`${CONSTANTS.API_URL}/participants/user/${whatsappNumber}`)
            .then(data => {
                data.user.roster = rosterCall;
                data.user.interactionHistory = calls;
                data.user.guideUsername = window.localStorage.getItem('username');
                setSelectedProfile(data.user);
            })
            .catch(err => {
                console.error(err);
            })
    }

    const callFormModal = <Modal
    open={modalActive}
    className="call-form-modal"
    onClose={handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
>
    <Box sx={style}>
    <Typography id="modal-modal-title" variant="h6" component="h6">
        Call Form
    </Typography>
    {
        selectedProfile.roster.callType === 'WC' && 
            <div style={{ color: 'green', marginTop: '6px', fontSize: '16px', marginBottom: '14px' }}>
                <Chip icon={<WavingHandIcon />} label="Welcome Call" color="success"/>
            </div>
    }
    {
        selectedProfile.roster.callType === 'GC' && 
            <div style={{ color: 'blue', marginTop: '6px', fontSize: '16px', marginBottom: '14px' }}>
                <Chip icon={<HandshakeIcon />} label="Guide Call" color="info"/>
            </div>
    }
    <form id="call-form" style={{ height: '90%', overflowY: 'scroll', overflowX: 'hidden' }}>
    <TextField id="filled-basic" label="Participant Name" sx={{mt:2}} variant="standard" defaultValue={selectedProfile.name} disabled fullWidth/>
    <TextField id="filled-basic" label="WhatsApp Number" sx={{mt:2}} variant="standard" defaultValue={selectedProfile.whatsappNumber} disabled fullWidth/>
    <TextField id="filled-basic" label="Calling Number" sx={{mt:2}} variant="standard" defaultValue={selectedProfile.callingNumber} fullWidth/>
    
    <div style={{ width: '100%', textAlign: 'center', marginTop: '20px' }}>
        <b>Form Link</b><br/>
        <Button variant="outlined" href={selectedProfile.roster.callType == "WC" ? welcomeFormLink : guideCallLink} sx={{ mt: 1, mb: 2 }} target="_blank">
        Link
        </Button>
    </div>


    <FormControl className='pers-form-in' sx={{ mt: 2 }} fullWidth>
        <InputLabel id="demo-simple-select-helper-label">Callback Type</InputLabel>
        <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={selectedProfile.roster.callback ? selectedProfile.roster.callback : "Original Call Time"}
            label="Callback Type"
            variant="outlined"
            onChange={(e) => setSelectedProfile({...selectedProfile, roster: {...selectedProfile.roster, callback: e.target.value}})}
            fullWidth
            >
                <MenuItem value="Original Call Time">Original Call Time</MenuItem>
                <MenuItem value="Callback 1">Callback 1</MenuItem>
                <MenuItem value="Callback 2">Callback 2</MenuItem>
        </Select>
    </FormControl>

    {
        selectedProfile.roster.callType === 'GC' 
        &&
        <div>
            <FormControl className='pers-form-in' sx={{ mt: 2, mb: 2 }} fullWidth disabled={selectedProfile.callType=='WC'}>
                <InputLabel id="demo-simple-select-helper-label">Call Exercise</InputLabel>
                <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={selectedProfile.roster.exercise ? selectedProfile.roster.exercise : "Zameen se Judne Wali"}
                    label="Call Exercise"
                    variant="outlined"
                    onChange={(e) => setSelectedProfile({...selectedProfile, roster: {...selectedProfile.roster, exercise: e.target.value}})}
                    fullWidth
                    >
                        <MenuItem value="Zameen se Judne Wali">Zameen se Judne Wali</MenuItem>
                        <MenuItem value="Dheere Saas Wali">Dheere Saas Wali</MenuItem>
                        <MenuItem value="Mannpasand Activity">Mannpasand Activity</MenuItem>
                        <MenuItem value="Mushkil ke Chote Steps">Mushkil ke Chote Steps</MenuItem>
                        <MenuItem value="Khud Pe Meherbaani">Khud Pe Meherbaani</MenuItem>
                        <MenuItem value="Agle Step ka Plan Banana">Agle Step ka Plan Banana</MenuItem>
                        <MenuItem value="Logon se Judna">Logon se Judna</MenuItem>
                        <MenuItem value="Thodi Help Chahiye">Thodi Help Chahiye</MenuItem>
                        <MenuItem value="Warning Signs">Warning Signs</MenuItem>
                        <MenuItem value="Raaste pe Wapas Aana">Raaste pe Wapas Aana</MenuItem>
                </Select>
            </FormControl>
        </div>
    }

    <div style={{ width: '100%', textAlign: 'center', marginTop: '16px' }}>
        <span style={{ marginTop: '12px' }}><b>Call Feedback</b></span><br/>
        <ButtonGroup size="large" sx={{mt: 2}} aria-label="Large button group">
            <Tooltip title="Resolved">
                <Button className={selectedProfile.roster.feedback === 'resolved' ? 'active-type':''} onClick={() => setSelectedProfile({...selectedProfile, roster: {...selectedProfile.roster, feedback: 'resolved'}})} key="Resolved"><TaskAltIcon /></Button>
            </Tooltip>
            <Tooltip title="Missed Call">
                <Button className={selectedProfile.roster.feedback === 'missed' ? 'active-type':''}  onClick={() => setSelectedProfile({...selectedProfile, roster: {...selectedProfile.roster, feedback: 'missed'}})} key="Missed"><PhoneMissedIcon /></Button>
            </Tooltip>
            <Tooltip title="Reschedule">
                <Button className={selectedProfile.roster.feedback === "reschedule" ? 'active-type':''}  onClick={() => setSelectedProfile({...selectedProfile, roster: {...selectedProfile.roster, feedback: 'reschedule'}})} key="Rescheduled"><EditCalendarIcon /></Button>
            </Tooltip>
            <Tooltip title="Opt Out">
                <Button className={selectedProfile.roster.feedback === 'OptOut' ? 'active-type':''}  onClick={() => setSelectedProfile({...selectedProfile, roster: {...selectedProfile.roster, feedback: 'OptOut'}})} key="Optout"><RemoveCircleOutlineIcon /></Button>
            </Tooltip>
        </ButtonGroup>
    </div>
    {
        selectedProfile.roster.feedback ==='resolved' &&
        selectedProfile.roster.callType === 'WC' &&
        <center style={{ marginTop: '20px' }}>
        <span style={{ color: 'darkgray', fontSize: '14px', marginBottom: '20px' }}>Add Recurring Guide Call Day and Time</span><br/>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker onChange={(value) => {setSelectedProfile({...selectedProfile, roster: {...selectedProfile.roster, recurringTime: value}})}}/>
        </LocalizationProvider>
        </center>

    }
    {
        selectedProfile.roster.feedback ==='reschedule' &&
        <center style={{ marginTop: '20px' }}>
        <span style={{ color: 'darkgray', fontSize: '14px', marginBottom: '20px' }}>Add Reschedule Call Day and Time</span><br/>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker onChange={(value) => {setSelectedProfile({...selectedProfile, roster: {...selectedProfile.roster, rescheduledStartTime: value}})}}/>
        </LocalizationProvider>
        </center>

    }
    <div style={{ width: '100%', textAlign: 'center', marginTop: '30px' }}>
        <Button variant="contained" onClick={submitFeedback}>Submit</Button>
    </div>
    </form>

        </Box>
    </Modal> 

    const callFormModalNew = <Modal
            open={modalActive}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h6">
                Call Form
            </Typography>
            {
                selectedProfile.roster.callType === 'WC' && 
                    <div style={{ color: 'green', marginTop: '6px', fontSize: '16px', marginBottom: '14px' }}>
                        <Chip icon={<WavingHandIcon />} label="Welcome Call" color="success"/>
                    </div>
            }
            {
                selectedProfile.roster.callType === 'GC' && 
                    <div style={{ color: 'blue', marginTop: '6px', fontSize: '16px', marginBottom: '14px' }}>
                        <Chip icon={<HandshakeIcon />} label="Guide Call" color="info"/>
                    </div>
            }
            <form id="call-form" style={{ height: '90%', overflowY: 'scroll', overflowX: 'hidden' }}>
            <TextField id="filled-basic" label="Participant Name" sx={{mt:2}} variant="standard" defaultValue={selectedProfile.name} disabled fullWidth/>
            <TextField id="filled-basic" label="WhatsApp Number" sx={{mt:2}} variant="standard" defaultValue={selectedProfile.whatsappNumber} disabled fullWidth/>
            <TextField id="filled-basic" label="Calling Number" sx={{mt:2}} variant="standard" defaultValue={selectedProfile.callingNumber} fullWidth/>
            <FormControl className='pers-form-in' sx={{ mt: 2 }} fullWidth>
                <InputLabel id="demo-simple-select-helper-label">Callback Type</InputLabel>
                <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={selectedProfile.roster.callback ? selectedProfile.roster.callback : "Original Call Time"}
                    label="Callback Type"
                    variant="outlined"
                    onChange={(e) => setSelectedProfile({...selectedProfile, roster: {...selectedProfile.roster, callback: e.target.value}})}
                    fullWidth
                    >
                        <MenuItem value="Original Call Time">Original Call Time</MenuItem>
                        <MenuItem value="Callback 1">Callback 1</MenuItem>
                        <MenuItem value="Callback 2">Callback 2</MenuItem>
                </Select>
            </FormControl>

            {
                selectedProfile.roster.callType === 'GC' 
                &&
                <div>
                    <FormControl className='pers-form-in' sx={{ mt: 2, mb: 2 }} fullWidth disabled={selectedProfile.callType=='WC'}>
                        <InputLabel id="demo-simple-select-helper-label">Call Exercise</InputLabel>
                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={selectedProfile.roster.exercise ? selectedProfile.roster.exercise : "Zameen se Judne Wali"}
                            label="Call Exercise"
                            variant="outlined"
                            onChange={(e) => setSelectedProfile({...selectedProfile, roster: {...selectedProfile.roster, exercise: e.target.value}})}
                            fullWidth
                            >
                                <MenuItem value="Zameen se Judne Wali">Zameen se Judne Wali</MenuItem>
                                <MenuItem value="Dheere Saas Wali">Dheere Saas Wali</MenuItem>
                                <MenuItem value="Mannpasand Activity">Mannpasand Activity</MenuItem>
                                <MenuItem value="Mushkil ke Chote Steps">Mushkil ke Chote Steps</MenuItem>
                                <MenuItem value="Khud Pe Meherbaani">Khud Pe Meherbaani</MenuItem>
                                <MenuItem value="Agle Step ka Plan Banana">Agle Step ka Plan Banana</MenuItem>
                                <MenuItem value="Logon se Judna">Logon se Judna</MenuItem>
                                <MenuItem value="Thodi Help Chahiye">Thodi Help Chahiye</MenuItem>
                                <MenuItem value="Warning Signs">Warning Signs</MenuItem>
                                <MenuItem value="Raaste pe Wapas Aana">Raaste pe Wapas Aana</MenuItem>
                        </Select>
                    </FormControl>

                    <div style={{ marginTop: '10px' }}>
                    <b>Exercises Practised</b><br />
                    <div>
                    <Checkbox size="small" /><span style={{ fontSize: '12px' }}>Zameen se Judne Wali</span>
                    <Checkbox size="small" /><span style={{ fontSize: '12px' }}>Dheere Saas Wali</span>
                    <Checkbox size="small" /><span style={{ fontSize: '12px' }}>Mannpasand Activity</span>
                    </div>
                    <div>
                    <Checkbox size="small" /><span style={{ fontSize: '12px' }}>Mushkil ke Chote Steps</span>
                    <Checkbox size="small" /><span style={{ fontSize: '12px' }}>Khud Pe Meherbaani</span>
                    <Checkbox size="small" /><span style={{ fontSize: '12px' }}>Agle Step ka Plan Banana</span>
                    </div>
                    <div>
                    <Checkbox size="small" /><span style={{ fontSize: '12px' }}>Logon se Judna</span>
                    <Checkbox size="small" /><span style={{ fontSize: '12px' }}>Thodi Help Chahiye</span>
                    <Checkbox size="small" /><span style={{ fontSize: '12px' }}>Warning Signs</span>
                    </div>
                    
                    <Checkbox size="small" /><span style={{ fontSize: '12px' }}>Raaste pe Wapas Aana</span><br/>
                    <TextField
                        label="Other Exercises"
                        id="standard-size-small"
                        size="small"
                        variant="standard"
                        fullWidth
                        sx={{ ml: 2, mb: 4 }}
                    />
                    </div>
                    <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', marginBottom: '30px'}}>
                        <b>Guide's perception of participant exercise engagement*</b>
                        <Box
                            sx={{
                                alignItems: 'center',
                                textAlign: 'center'
                            }}
                            width='100%'
                            >
                            <Rating
                                name="hover-feedback"
                                value={ratingValue}
                                precision={1}
                                sx={{ mt: 2, mb: 2 }}
                                getLabelText={getLabelText}
                                onChange={(event, newValue) => {
                                setRatingValue(newValue);
                                }}
                                onChangeActive={(event, newHover) => {
                                setRatingHover(newHover);
                                }}
                                emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                            />
                        </Box>
                        {ratingValue !== null && (
                                <Box sx={{ ml: 2, color: 'gray', fontSize: '14px' }}>{ratingLabels[ratingHover !== -1 ? ratingHover : ratingValue]}</Box>
                            )}

                        {ratingValue === 1 && 
                            <TextField
                                label="Other perception"
                                id="standard-size-small"
                                size="small"
                                variant="standard"
                                sx={{ ml: 2, mb: 4, mt: 2 }}
                            />}
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <b>Did the participant say anything to indicate they might be feeling suicidal?*</b>
                        <ToggleButtonGroup
                            sx={{ mt: 2, mb: 2 }}
                            color="primary"
                            size="small"
                            value={selectedProfile.roster.suicidalSigns || "No"}
                            exclusive
                            onChange={(e) => {setSelectedProfile({...selectedProfile, roster: {...selectedProfile.roster, suicidalSigns: e.target.value}})}}
                            aria-label="Platform"
                            >
                                <ToggleButton value="No">No</ToggleButton>
                                <ToggleButton value="Yes">Yes</ToggleButton>
                                <ToggleButton value="Other">Other</ToggleButton>
                        </ToggleButtonGroup><br/>
                        {selectedProfile.roster.suicidalSigns === 'Other' && 
                            <TextField
                                label="Other Suicidal Signs"
                                id="standard-size-small"
                                size="small"
                                variant="standard"
                                sx={{ ml: 2, mb: 2 }}
                            />}
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <b>Did the participant mention financial issues?*</b>  <Tooltip sx={{ verticalAlign: 'middle', cursor: 'pointer' }} title={financeTip} arrow ><InfoOutlinedIcon /></Tooltip><br/>
                        <ToggleButtonGroup
                                sx={{ mt: 2, mb: 2 }}
                                color="primary"
                                size="small"
                                value={selectedProfile.roster.financeProblems || "No"}
                                exclusive
                                onChange={(e) => {setSelectedProfile({...selectedProfile, roster: {...selectedProfile.roster, financeProblems: e.target.value}})}}
                                aria-label="Platform"
                                >
                                    <ToggleButton value="No">No</ToggleButton>
                                    <ToggleButton value="Yes">Yes</ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        <b>R E A P</b><br/>
                        <span style={{ color: 'gray', fontSize: '15px', marginTop: '10px' }}>Ext Rating</span><br/>
                        <Rating
                                name="hover-feedback"
                                value={selectedProfile.roster.extRating}
                                precision={0.5}
                                getLabelText={getLabelText}
                                onChange={(event, newValue) => {
                                setSelectedProfile({...selectedProfile, roster: {...selectedProfile.roster, extRating: newValue}});
                                }}
                                size="small"
                                emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                            />
                        
                    </div>
                </div>
                
            }
            

            <div style={{ width: '100%', textAlign: 'center', marginTop: '16px' }}>
                <span style={{ marginTop: '12px' }}><b>Call Feedback</b></span><br/>
                <ButtonGroup size="large" sx={{mt: 2}} aria-label="Large button group">
                    <Tooltip title="Resolved">
                        <Button className={selectedType === 'resolved' ? 'active-type':''} onClick={() => setSelectedType('resolved')} key="resolved"><TaskAltIcon /></Button>
                    </Tooltip>
                    <Tooltip title="Missed Call">
                        <Button className={selectedType === 'missed' ? 'active-type':''}  onClick={() => setSelectedType('missed')} key="Missed"><PhoneMissedIcon /></Button>
                    </Tooltip>
                    <Tooltip title="Reschedule">
                        <Button className={selectedType === 'reschedule' ? 'active-type':''}  onClick={() => setSelectedType('reschedule')} key="Rescheduled"><EditCalendarIcon /></Button>
                    </Tooltip>
                    <Tooltip title="Opt Out">
                        <Button className={selectedType === 'Opt Out' ? 'active-type':''}  onClick={() => setSelectedType('Opt Out')} key="Optout"><RemoveCircleOutlineIcon /></Button>
                    </Tooltip>
                </ButtonGroup>
            </div>
            </form>

            </Box>
        </Modal> 

    return (
        <>
            {callFormModal}
            <div className='content'>
                <Profile data={selectedProfile}/>
            </div>
            <div className='scheduled-calls-menu'>
                <h5>Scheduled Calls</h5>
                <div className='calls-container'>
                    {
                        loading &&
                        <CircularProgress />

                    }
                    {
                        ! loading &&
                        rosterCalls.length == 0 &&
                        <pre>No Scheduled Calls</pre>
                    }
                    {
                        !loading &&
                        rosterCalls.map((rosterCall) => {
                            console.log(rosterCall)
                            return <div className='scheduled-call-tile' onClick={() => {selectProfile(rosterCall.whatsappNumber, rosterCall)}}>
                                <span style={{ fontWeight: 'bold' }}>{rosterCall.callType == "WC" ? <WavingHandIcon style={{ color: 'green', fontSize: '16px' }} /> : <HandshakeIcon color="info" style={{ fontSize: '20px', verticalAlign: 'middle' }}/>}&nbsp;{rosterCall.name} | {rosterCall.whatsappNumber}</span>
                                <pre>@{rosterCall.callTime}</pre>
                            </div>
                        })
                    }
                </div>
                <div className='call-fab' onClick={() => {}}>
                    <FaPhone style={{ fontSize: '30px', color: 'white' }} onClick={handleOpen}/>
                </div>
            </div>
        </>
    )
}