import { useState, useEffect } from 'react';
import Users from '../../components/Users/Users';
import ScheduledCalls from '../../components/ScheduledCalls/ScheduledCalls';
import Navbar from '../../components/Navbar/Navbar';
import './Dashboard.css';
import whiteLogo from '../../assets/white-logo.png';
import UsersTopView from '../../components/UsersTopView/UsersTopView';
import LogoutIcon from '@mui/icons-material/Logout';

export default function Dashboard () {

    const [selectedTab, setSelectedTab] = useState(
        localStorage.getItem('currentTab') == null ? 'users' : localStorage.getItem('currentTab')
    );

    useEffect(()=>{
      if (!window.localStorage.getItem('username')) window.location.href = '/login';

    },[])

    useEffect(() => {
    localStorage.setItem('currentTab', selectedTab);
    }, [selectedTab]);

    return (
        <>
          <div className='vertical-nav'>
            <img src={whiteLogo} height='45px' width='45px' style={{ marginTop: '10px' }} />
            <LogoutIcon onClick={()=>{window.localStorage.clear(); window.location.href='/login'}} className='logout-btn'/>
          </div>

          <Navbar selectedTab={selectedTab} setSelectedTab={setSelectedTab}/>
          <div className="dash-content">
            {
              selectedTab === 'calls' && 
              <ScheduledCalls />  
            }
            {
              selectedTab === 'users' &&
              <Users />
            }
            {
              selectedTab === 'analysis' &&
              <UsersTopView />
            }
          </div>
        </>
    )
}