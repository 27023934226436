import ExpandFAB from "./ExpandFAB";
import NavbarBody from "./NavbarBody";
import './Navbar.css';
import { useState } from "react";

export default function Navbar({selectedTab='users', setSelectedTab={}}) {
    const [expanded, setExpanded] = useState(false);
    
    return (
        <div className="navbar">
            <NavbarBody expand={expanded} selected={selectedTab} setSelectedTab={setSelectedTab}/>
            <ExpandFAB expanded={expanded} setExpanded={setExpanded}/>
        </div>
    )
}