import { FiChevronsRight, FiChevronsLeft } from 'react-icons/fi';
import './ExpandFAB.css';

export default function ExpandFAB({expanded, setExpanded}) {
    return (
        <div className="nav-expand-fab" onClick={() => setExpanded(!expanded)} style={{ marginLeft: expanded ? "200px" : "80px"}}>
            {
                expanded
                ? <FiChevronsLeft style={{ marginTop: '15%', fontSize: '20px' }}/>
                : <FiChevronsRight style={{ marginTop: '15%', fontSize: '20px' }}/>

            }
            
        </div>
    )
}