import blueLogo from '../../assets/blue-logo.png';
import IconInput from '../../components/misc/IconInput/IconInput';
import { FaRegUser, FaKey } from "react-icons/fa6";
import './Login.css';
import { post } from '../../services/http';
import { CONSTANTS } from '../../constants/app.constants';
import { useState } from 'react';
import background from '../../assets/kayabg.webp';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import AccountCircle from '@mui/icons-material/AccountCircle';
import VpnKeyIcon from '@mui/icons-material/VpnKey';

export default function Login () {

    const [username, setUsername] = useState('');
    const [password, setPassword ] = useState('');

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    }

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    }


    const loginRequest = () => {
        post(`${CONSTANTS.API_URL}/login`, {
            username,
            password 
        }).then(response => {
            window.localStorage.setItem('token', response.token);
            window.localStorage.setItem('username', response.username);
            window.location.href = "/dashboard";
        }).catch(err => {
            console.error(err)
        })
    }

    const loginForm = <center>
        <div className="notification">HI</div>
        <div className="login-form" style={{ backgroundColor: 'white', zIndex: '999' }}>
        <img src={blueLogo} /><br/>

            <FormControl 
                variant="standard"
                size='normal'
                style={{ width: '300px' }}
            >
                <InputLabel htmlFor="input-with-icon-adornment">
                Username
                </InputLabel>
                <Input
                id="input-with-icon-adornment"
                onChange={handleUsernameChange}
                startAdornment={
                    <InputAdornment position="start"
                    sx={{ mr: 2 }}>
                    <AccountCircle />
                    </InputAdornment>
                }
                />
            </FormControl><br/>
            <FormControl 
                variant="standard"
                size='normal'
                style={{ width: '300px', marginTop: '25px' }}
            >
                <InputLabel htmlFor="input-with-icon-adornment">
                Password
                </InputLabel>
                <Input
                id="input-with-icon-adornment"
                onChange={handlePasswordChange}
                type='password'
                startAdornment={
                    <InputAdornment position="start"
                    sx={{ mr: 2 }}>
                    <VpnKeyIcon />
                    </InputAdornment>
                }
                />
            </FormControl><br/>
            <Button style={{ marginTop: '50px' }} variant='contained' onClick={loginRequest}>Login</Button>
        </div>
    </center>;

    return (
        <div style={{ height: '100vh', backgroundImage: `url(${background})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', transform: 'translate(0px, -30px)', backgroundColor: 'rgba(0,0,0,0.5)' }}>
            <div className='blur-layer'>
            </div>
            {loginForm}
        </div>
    )
}